export class EditableField {

    constructor($container, fieldName, callback) {
        this._$container = $container;
        this._fieldName = fieldName;
        this._callback = callback;

        let $editLink = this.getEditableLink();
        $editLink.on("click.editableFieldInputClick", this.appendInput.bind(this));
        this._$container.prepend($editLink);
    }

    getEditableLink() {
        return $("<a href=\"#\" title=\"заполнить значение\">заполнить</a>");
    }

    appendInput(event) {
        event.preventDefault();
        event.stopPropagation();
        let $input, $editLink,
            placeholders = {
                "phone": "+9 999 999 99 99",
                "bdate": "ДД.ММ.ГГГГ",
                "mail": "name@domain.ru"
            };
        $input = $(`<input type="text" class="edInt" id="${this._fieldName}" placeholder="${placeholders[this._fieldName]}" />`);
        $editLink = this._$container.find("a");
        $editLink.hide();
        this._$container.prepend($input);
        $input
            .focus()
            .on("keydown", this._callback);
        $(document).on("click.removeFieldEditInput", (e) => {
            let $clicked = $(e.target);
            if (!$input.is($clicked) && $clicked.closest($input).length === 0 && !$clicked.is($input.siblings())) {
                $input.remove();
                $editLink.show();
            }
        });
    }
}