/**
 *
 * @constructor
 */

export function NotificationSystem() {
    'use strict';
    this.init();
}

NotificationSystem.prototype.init = function () {
    'use strict';
    if (!this.checkAbility()) {
        alert('Ваш браузер не поддерживает HTML Notifications, из-за чего Вы можете пропустить выжные сообщения. Обновите браузер!');
        return false;
    }
    this.options = {};
};

NotificationSystem.prototype.checkAbility = function () {
    'use strict';
    return "Notification" in window;
};

NotificationSystem.prototype.checkGranted = function () {
    'use strict';
    return Notification.permission === "granted";
};

NotificationSystem.prototype.checkDenied = function () {
    'use strict';
    return Notification.permission === 'denied';
};

NotificationSystem.prototype.send = function (id, title, body) {
    'use strict';
    if (this.alreadyShow()) {
        return false;
    }
    this.options.body = body;
    this.options.tag = id;
    var notification = new Notification(title, this.options);
    notification.onclick = this.notificationOnClick.bind(this);
};

NotificationSystem.prototype.notificationOnClick = function (e) {
    'use strict';
    console.log("click " + e.target.tag);
    this.setAlreadyShown(e.target.tag);
};

NotificationSystem.prototype.alreadyShow = function (tag) {
    'use strict';
    return Cookies.get("message_" + tag) === "read";
};

NotificationSystem.prototype.setAlreadyShown = function (tag) {
    'use strict';
    Cookies.set("message_" + tag, "read");
};
