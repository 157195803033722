import {diary}  from "../index";
import {Router} from "./Router";
import { Tooltip } from 'bootstrap';
import "jquery-validation";
import "jquery-validation/dist/additional-methods";

const axios = require('axios').default;
const devMode = process.env.NODE_ENV !== 'production';

export class App {

    constructor() {
        /**
         *  @type {boolean}
         */
        this.debug = devMode;
        this.config = {};
        this.constants = {};
        this.session = {};
        this.request = {};
        this.initHandlers();
        this.initNavPanelOpener();
        this.fixTouchstartPassiveMode();
        this.initDefaults();
        this.initPageLoader();
    }

    /**
     * инициализирует JS класс для заданного маршрута, или по прямому указанию
     * @param fileName - имя JS файла с одноименным классом,
     * который нужно подключить для этой страницы,
     * если она не управляется автоматическим роутингом
     * @param params - дополнительные параметры для передачи в JS класс
     */
    async initPageLoader(fileName = "", params = {}) {
        Promise.all([this.initConfig(), this.initConstants(), this.getSessionData()])
               .then(r => {
                   this.request = JSON.parse(this.session.request) || "";

                   axios.defaults.headers['X-CSRF-TOKEN'] = this.session.csrf_token;

                   $.ajaxSetup({
                       headers: {
                           "X-CSRF-TOKEN": this.session.csrf_token,
                       },
                   });
                   Router.initPageLoader(fileName, params);
               });
    }

    initDefaults() {

        axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        axios.defaults.headers.HTTP_X_REQUESTED_WITH = 'XMLHttpRequest';
        axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';
        axios.defaults.paramsSerializer = function (params) {
            return jQuery.param(params);
        };

        jQuery.validator.setDefaults({
            debug: false,
            ignore: [],
            lang: 'ru',
            success: function (label) {
                label.html("готово").addClass("text-success ms-2");
            },
        });

    }

    initHandlers() {

        let forms = document.getElementsByClassName("needs-validation");
        // Loop over them and prevent submission
        let validation = Array.prototype.filter.call(forms, function (form) {
            "use strict";
            form.addEventListener("submit", function (event) {
                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                }
                form.classList.add("was-validated");
            }, false);
        });

        $('#helpModal').on('show.bs.modal', function (e) {
            let $button = $(e.relatedTarget),
                $modal = $(this),
                url = "/app/views/helps/" + $button.data("load-url") + ".html";
            // or, load content from value of data-remote url
            $modal.find('.modal-body').load(url);
        });

        window.addEventListener("popstate", function (e) {
            "use strict";
            //установим объект событие
            diary.event = e;
            if (e.state !== null) {
                diary.loadHistory(e.state);
            }
        }, false);

        // запросы на клики по ссылкам удаления
        $(document).on("click", "a[data-action='delete']", diary.getConfirm.bind(this, "Действительно удалить?"));

        // отлавливать запросы на открытие в модальном окне
        $(document).on("click", "*[data-in-modal='Y']", async (event) => {
            await Router.routeInModal(event);
            await app.initPageLoader();
        });

        /**
         * разберем старые onclick функции, распределим их по модулям
         */
        $(document).on("click.routeOldStyleLink", "*[onclick]", Router.routeOldStyleLink);

        window.onerror = function (msg, url, line, columnNo, error) {
            let string = msg.toLowerCase(),
                substring = "script error",
                message = "";
            if (string.indexOf(substring) > -1) {
                message = 'Script Error: See Browser Console for Detail';
            } else {
                message = [
                    'Message: ' + msg,
                    'URL: ' + url,
                    'Line: ' + line,
                    'Column: ' + columnNo,
                    'Error object: ' + JSON.stringify(error),
                ].join(' - ');
            }

            axios.post('/app/models/ajax/jsLogHandler.php',
                {
                    new_xhr_request_form: "Y",
                    code: "JS",
                    msg: message,
                    url: url,
                    line: line,
                },
            )
                 .then((response) => {
                     if (this.debug) {
                         console.log("Записана ошибка JS в log файл!");
                     }
                 })
                 .catch((error) => {
                     if (this.debug) {
                         console.log("Не удалось записать ошибку JS в log файл!");
                     }
                 });

            return false;
        };


        if (!this.debug) { //TODO доработать таймер сессии и вынести в отдельный модуль
            $(document).on("click.checkSessionAlive", function (event) {
                "use strict";
                if (diary.sessionTimer === undefined) {
                    diary.sessionTimer = setTimeout(function run() {
                        diary.elapsedTime++;
                        setTimeout(run, 1000);
                    }, 1000);
                    return;
                }
                if ((diary.elapsedTime * 1000) > diary.SESSION_TIME) {
                    event.preventDefault();
                    event.stopPropagation();
                    clearTimeout(diary.sessionTimer);
                    window.confirm("Время сессии истекло, страница будет перезагружена!");
                    diary.refreshPage();
                }
                diary.elapsedTime = 0;
            });
        }

    }

    fixTouchstartPassiveMode() {
        jQuery.event.special.touchstart =
            {
                setup: function (_, ns, handle) {
                    if (ns.includes("noPreventDefault")) {
                        this.addEventListener("touchstart", handle, {passive: false});
                    } else {
                        this.addEventListener("touchstart", handle, {passive: true});
                    }
                },
            };
    }

    async initConfig() {
        let answer = await axios('/app/models/ajax/getConfig.php');
        this.config = await answer.data;
    }

    async initConstants() {
        let answer = await axios('/app/models/ajax/getConstants.php');
        this.constants = await answer.data;
    }

    async getSessionData() {
        let answer = await axios('/app/models/ajax/getSessionData.php');
        this.session = await answer.data;
    }

    setConfig(config) {
        this.config = config;
    }

    getConfig() {
        return this.config;
    }

    async consoleLog(text) {
        console.log(text);
    }


    initNavPanelOpener() {
        const $body = $("body"),
            $toggle = $("#navToggle"),
            $panel = $("#leftNav"),
            $panelLinks = $panel.find("a"),
            $back = $("#back");

        const openPanel = (event) => {
            $panel.appendTo("body")
                  .addClass("opened");
            $toggle
                .addClass("navToggled")
                .addClass("text-secondary");
            $body.addClass("navPanelOpened");
            $back.show()
                 .on("click.closePanel", managePanel);
            $panelLinks.on("click.closePanel", managePanel);
        };

        const closePanel = () => {
            $panel.prependTo("#menu-container")
                  .removeClass("opened");
            $toggle
                .removeClass("navToggled")
                .removeClass("text-secondary");
            $body.removeClass("navPanelOpened");
            $back.hide()
                 .off("click.closePanel");
            $panelLinks.off("click.closePanel");
        };

        const managePanel = () => {
            $panel.hasClass("opened") ? closePanel() : openPanel();
        };

        $body.on("click.managePanel", "#navToggle", managePanel);
    }

    paramsSerializer(params) {
        let result = '';
        Object.keys(params).forEach(key => {
            result += `${key}=${encodeURIComponent(params[key])}&`;
        });
        return result.substr(0, result.length - 1);
    }

    async getConfirm(msg, event) {
        let href;
        if (event) {
            event.preventDefault();
            href = $(event.currentTarget).attr('href');
        }
        let promiseResolve, promiseReject,
            modal = document.getElementById('confirmModal'),
            btnYes = modal.querySelector(".modal-btn-confirm"),
            btnNo = modal.querySelector(".modal-btn-deny"),
            body = modal.querySelector(".modal-body");

        const closeAndConfirm = () => {
            hideModal();
            if (href) {
                window.location.href = href;
            }
            return true;
        };

        const closeAndDeny = () => {
            hideModal();
            return false;
        };

        const hideModal = () => {
            body.innerHTML = "";
            $(modal).modal('hide');
            btnYes.removeEventListener("click", promiseReject, false);
            btnNo.removeEventListener("click", promiseReject, false);
        };

        return new Promise(
            (resolve, reject) => {
                body.innerHTML = msg;
                $(modal).modal('show');
                promiseResolve = resolve;
                promiseReject = reject;
                btnYes.addEventListener("click", promiseResolve, false);
                btnNo.addEventListener("click", promiseReject, false);
            },
        ).then(() => {
            closeAndConfirm();
            return true;
        })
         .catch(() => {
             closeAndDeny();
             return false;
         });

    };
}
