import {DocsIssues} from "../enums/DocsIssues";

export class DocsIssuesSelect {

    constructor() {
    }

    static getDocsIssueSelect(issuedSelected) {
        let options = '', selected = "", includeFirst = false, noSelection = true, out;
        DocsIssues.forEach((item) => {
            if (!issuedSelected && !includeFirst) {
                options += "<option value='' selected>кем выдан документ</option>";
                includeFirst = true;
            }
            if (item === issuedSelected) {
                selected = "selected";
                noSelection = false;
            }
            options += `<option value='${item}' ${selected}>${item}</option>`;
            selected = "";
        });
        if (noSelection && issuedSelected) {
            out = "<select>";
            options += "<option value='other' selected>...другое...</option>";
            let inputField = `<input class="mb-2" type="text" id='studentCIs' value='${issuedSelected}'>`;
            out += options;
            out += "</select>";
            out += inputField;
        } else {
            out = "<select id='studentCIs'>";
            options += "<option value='other'>...другое...</option>";
            out += options;
            out += "</select>";
        }
        return out;
    }

}
