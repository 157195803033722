export class Utils {

    /**
     * капитализирует первую букву в строке
     * @return {string}
     * @param  string {String}
     */
    static capitalize(string) {
        return string && string.charAt(0).toUpperCase() + string.slice(1);
    }

    /**
     * проверяет, не пустая ли строке
     * @return {boolean}
     * @param string
     */
    static isNotEmpty(string) {
        return string.length > 0;
    }

    /**
     * принимает на входе время в 24 часовом формате (11:11) и возвращает bool
     * @param time - время в 24 часовом формате (11:11)
     * @return boolean
     */
    static timeInputWrong(time) {
        "use strict";
        let regEx = new RegExp(/^(([0,1][0-9])|(2[0-3])):[0-5][0-9]$/);
        return time !== "" && !regEx.test(time);
    }

    static validateEmail(email) {
        let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    static checkPasswordDifficult(password) {
        "use strict";
        let s_letters = "qwertyuiopasdfghjklzxcvbnm", // Буквы в нижнем регистре
            b_letters = "QWERTYUIOPLKJHGFDSAZXCVBNM", // Буквы в верхнем регистре
            digits = "0123456789", // Цифры
            specials = "!@#$%^&*()_-+=\|/.,:;[]{}", // Спецсимволы
            is_s = false, // Есть ли в пароле буквы в нижнем регистре
            is_b = false, // Есть ли в пароле буквы в верхнем регистре
            is_d = false, // Есть ли в пароле цифры
            is_sp = false, // Есть ли в пароле спецсимволы
            rating = 0,
            totalPasswordRating = 0;

        for (let i = 0; i < password.length; i++) {

            /* Проверяем каждый символ пароля на принадлежность к тому или иному типу */
            if (!is_s && s_letters.indexOf(password[i]) !== -1) {
                is_s = true;
            } else if (!is_b && b_letters.indexOf(password[i]) !== -1) {
                is_b = true;
            } else if (!is_d && digits.indexOf(password[i]) !== -1) {
                is_d = true;
            } else if (!is_sp && specials.indexOf(password[i]) !== -1) {
                is_sp = true;
            }
        }
        if (is_s) {
            rating++;
        } // Если в пароле есть символы в нижнем регистре, то увеличиваем рейтинг сложности
        if (is_b) {
            rating++;
        } // Если в пароле есть символы в верхнем регистре, то увеличиваем рейтинг сложности
        if (is_d) {
            rating++;
        } // Если в пароле есть цифры, то увеличиваем рейтинг сложности
        if (is_sp) {
            rating++;
        } // Если в пароле есть спецсимволы, то увеличиваем рейтинг сложности
        /* Далее идёт анализ длины пароля и полученного рейтинга, и на основании этого готовится текстовое описание сложности пароля */
        if (password.length < 6 && rating < 3) {
            totalPasswordRating = 3;
        } //"Простой"
        else if (password.length < 6 && rating >= 3) {
            totalPasswordRating = 4;
        } //"Средний";
        else if (password.length >= 8 && rating < 3) {
            totalPasswordRating = 4;
        } //"Средний";
        else if (password.length >= 8 && rating >= 3) {
            totalPasswordRating = 5;
        } //"Сложный";
        else if (password.length >= 6 && rating === 1) {
            totalPasswordRating = 3;
        } //"Простой";
        else if (password.length >= 6 && rating > 1 && rating < 4) {
            totalPasswordRating = 4;
        } //"Средний";
        else if (password.length >= 6 && rating === 4) {
            totalPasswordRating = 5;
        } //"Сложный";
        return totalPasswordRating;
    }

    static checkUrl(url, callback) {
        "use strict";
        if (!$.isFunction(callback)) {
            throw Error("Not a valid callback");
        }

        $.ajax({
            type: "HEAD",
            url: url,
            success: $.proxy(callback, this, true),
            error: $.proxy(callback, this, false)
        });

    }

    static toArray(obj) {
        "use strict";
        let ar = [],
            key;
        for (key in obj) {
            if (!obj.hasOwnProperty(key)) continue;
            ar.push(obj[key]);
        }
        return ar;
    }

    /***
     number - исходное число
     decimals - количество знаков после разделителя
     dec_point - символ разделителя
     thousands_sep - разделитель тысячных
     ***/
    static number_format(number, decimals, dec_point, thousands_sep) {
        "use strict";
        number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
        let n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof thousands_sep === "undefined") ? "," : thousands_sep,
            dec = (typeof dec_point === "undefined") ? "." : dec_point,
            s = "",
            toFixedFix = function(n, prec) {
                let k = Math.pow(10, prec);
                return "" + (Math.round(n * k) / k)
                    .toFixed(prec);
            };

        // Fix for IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec) : "" + Math.round(n))
            .split(".");
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || "")
            .length < prec) {
            s[1] = s[1] || "";
            s[1] += new Array(prec - s[1].length + 1)
                .join("0");
        }
        return s.join(dec);
    }

    static isInteger(value) {
        return !isNaN(value) && (function(x) {
            return (x || 0) === x;
        })(parseFloat(value));
    }

    static formatSize(bytes) {
        if (bytes === 0) {
            return "0.00 B";
        }
        let e = Math.floor(Math.log(bytes) / Math.log(1024));
        return (bytes / Math.pow(1024, e)).toFixed(0) + ' ' + ' KMGTP'.charAt(e) + 'B';
    }

    static translit(str, engToRus) {
        let
            rus = "щ   ш  ч  ц  ю  я  ё  ж  ъ  ы  э  а б в г д е з и й к л м н о п р с т у ф х ь".split(/ +/g),
            eng = "shh sh ch cz yu ya yo zh `` y' e` a b v g d e z i j k l m n o p r s t u f x `".split(/ +/g)
        ;
        for (let x = 0; x < rus.length; x++) {
            str = str.split(engToRus ? eng[x] : rus[x]).join(engToRus ? rus[x] : eng[x]);
            str = str.split(engToRus ? eng[x].toUpperCase() : rus[x].toUpperCase()).join(engToRus ? rus[x].toUpperCase() : eng[x].toUpperCase());
        }
        return str;
    }

    /**
     * @param length {number}
     * @return {string}
     */
    static getRandomID(length = 9) {
        // Math.random should be unique because of its seeding algorithm.
        // Convert it to base 36 (numbers + letters), and grab the first 9 characters
        // after the decimal.
        return Math.random().toString(36).substring(2, length);
    }

    static getRandomInt(min, max) {
        min = (min === undefined) ? "100000" : min;
        max = (max === undefined) ? "999999" : max;
        return Math.floor(Math.random() * (max - min)) + min;
    }

    static showNotification(msg) {
        let $note = $("<div id='sectionEditNote'></div>"),
            event = event || window.event,
            width = 200,
            height = 40;
        if (event === undefined) {
            return;
        }

        $note
            .appendTo($("body"))
            .html(msg)
            .css({
                position: "absolute",
                width: width,
                border: "1px solid rgb(210, 208, 188);",
                top: event.pageY - $note.height() + "px",
                left: event.pageX + "px",
                background: "#f9f6df",
                padding: "5px",
                fontSize: "small",
                zIndex: "9999"
            })
            .show();
        $(document).on("click.showNotification", function() {
            $("#sectionEditNote").remove();
            $(document).off("click.showNotification");
        });
    }

    /**
     * формирует рандомный hex для цвета в рамках определенного набора, установленного параметром choiceHexSet
     * @param {string} choiceHexSet - набор hex символов для формирования цвета - setOne | setTwo
     * @returns {string}
     */
    static getRandomBackground(choiceHexSet) {
        let hexSet = {
                setOne: ["8", "9", "A", "B", "C", "D", "E", "F"],
                setTwo: ["4", "5", "6", "7", "8", "9", "A", "B"]
            },
            color = "";
        if (choiceHexSet === undefined) {
            choiceHexSet = "setOne";
        }
        for (let ma = 0; ma < 6; ma++) {
            color += hexSet[choiceHexSet][Math.floor(Math.random() * 8)];
        }
        return "#" + color;
    }

    /**
     * собирает строку с ФИО, подставляет ? если имя не указано (а должно бы быть)
     * @param f
     * @param i
     * @param o
     * @returns {*}
     */
    static concatFio(f, i, o) {
        let fio = "";
        fio += (f && f.length > 0) ? `${f.trim()}` : "";
        fio += (i && i.length > 0) ? ` ${i.trim()}` : " ? ";
        fio += (o && o.length > 0) ? ` ${o.trim()}` : "";
        return fio;
    }

    static getCoords(elem) {
        if (elem instanceof jQuery) {
            elem = elem.get(0);
        }
        let box = elem.getBoundingClientRect();
        return {
            top: box.top,
            left: box.left
        };
    }

    /**
     * корректирует позицию datepicker относительно input в модальном окне
     * @param input -  элемент к которому прикреплен datepicker
     * @param inst - контейнер с datepicker
     */
    static correctInputPosition(input, inst) {
        let coords = Utils.getCoords(input);
        setTimeout(function() {
            inst.dpDiv.css( { top: coords.top + input.offsetHeight + 'px', left: coords.left + 'px' } );
        }, 0);
    }

    static getDateForDatepicker(element) {
        let value, dateFormat = app.config.DEFAULT_DATEPICKER_DATE_FORMAT;
        if (element instanceof jQuery) {
            value = element.val();
        }else{
            value = element;
        }
        try {
            return $.datepicker.parseDate(dateFormat, value);
        } catch (error) {
            return null;
        }
    }

    /**
     * устанавливает логин по ФИО - ФамилияИО
     * @param {string} f
     * @param {string} i
     * @param {string} o
     * @returns {String}
     */
    static setLoginFromFio(f,i,o) {
        let login = "";
        login += (f) ? `${f.trim()}` : "";
        login += (i) ? `_${i.trim().charAt(0).toUpperCase()}` : "";
        login += (o) ? `${o.trim().charAt(0).toUpperCase()}` : "";
        return login;
    }
}
