export class Modal {

    constructor(data, size, callback) {

        this.$body = $('body');
        this.$modal = $('#baseModal');
        this.$modalHeader = $("#baseModalLabel");
        this.$modalDialog = this.$modal.find(".modal-dialog");
        this.$modalBody = this.$modal.find(".modal-body");
        this.$modalFooter = this.$modal.find(".modal-footer");
        this.size = size || "lg";
        this.defaultSizeClass = "modal-lg";
        this.sizeClass = "modal-" + this.size;
        this.$pageHeader = "";
        this.closeModal = this.closeModal.bind(this);
        this.onModalOpen = this.onModalOpen.bind(this);
        this.onModalHide = this.onModalHide.bind(this);
        this.$modal.on('show.bs.modal', this.onModalOpen);
        this.$modal.on('hidden.bs.modal', this.onModalHide);
        this.$modalBody.html(data);
        this.callback = callback;
        // сначала поместим в модал весь контент, а потом уже будем показывать
        this.$modal.modal('show');
    }

    closeModal(event) {
        event.preventDefault();
        this.$modal.modal('hide');
        $(document).off("click.cancelModalClickEvent")
    };

    onModalOpen() {
        // операции с модальным окном
        this.$modalDialog
            .removeClass(this.defaultSizeClass)
            .addClass(this.sizeClass);
        this.$modalFooter.hide();
        this.$modal.off('show.bs.modal');
        this.$modal.modal('show');
        this.$pageHeader = this.$modalBody.find("h1");
        if (this.$pageHeader.get() && this.$pageHeader.get().length > 0) {
            this.$modalHeader.html(this.$pageHeader.html());
            this.$pageHeader.hide();
        } else {
            this.$modalHeader.hide();
        }
        $(document).on(
            "click.cancelModalClickEvent",
            ".modal-body .btn-cancel, .modal-body .btn-close",
            this.closeModal
        )
    }

    onModalHide() {
        this.$modalDialog
            .removeClass(this.sizeClass)
            .addClass(this.defaultSizeClass);
        // что-то с бутстрапом - ставит свойства и не убирает 
        this.$body.css(
            {
                'overflow': '',
                'padding-right': ''
            }
        );
        this.$pageHeader.show();
        this.$modalHeader.show().html("");
        this.$modal.find(".modal-footer").show();
        this.$modalBody.html("");
        $(".modal-backdrop").hide();
        if (this.callback) {
            this.callback();
        }
    }

}
