// todo все методы этого объекта следует определить в приложении App, а Diary должен исчезнуть

export function Diary() {

    let DEBUG = true,
        LOCAL_STATE_NAME = "ECIT_DIARY_LS_NAME",
        IS_NEW = "IS_NEW",
        LS_STATE_NAME = "ECIT_HISTORY_STATE",
        STATUS = {
            SUCCESS: 1,
            ERROR: 0,
        },
        _this = this,
        $body = $("body"),
        $pageContent = $("#pageContent"),
        $contentHeader = $("#contentHeader"),
        $popUp = $("#popUp"),
        $back = $("#back"),
        $popUpContent = $("#popUpContent"),
        $popUpHeader = $("#popUpHeader"),
        $closeButton = $(".close_button"),
        options = {
            modalBodyClass: "modal-open",
        },
        appPath = "/app/",
        modelsPath = appPath + "models/",
        controllerPath = appPath + "controllers/",
        php_router_adapter = appPath + "jsrouter.php",
        msg = {
            needSave: "Вы действительно хотите закрыть форму? Все несохраненные данные будут утеряны!",
            closeSuccess: "Данные успешно сохранены! Закрыть форму?",
            closeFalse: "Ошибка схранения данных! Попробуйте позже, а если ошибка повторится, обратитесь к администратору. Закрыть форму?",
            dataFalse: "Ошибка получения данных! Попробуйте позже, а если ошибка повторится, обратитесь к администратору.",
            debugMode: "<h3>Тестовый режим! Отключить в admin.ecit.ru/www/app/js/functions_new.js</h3>",
        };

    this.diaryJsData = {};
    this.DEBUG = DEBUG;
    this.LOCAL_STATE_NAME = "ECIT_DIARY_LS_NAME";
    this.IS_NEW = "IS_NEW";
    this.LS_STATE_NAME = "ECIT_HISTORY_STATE";
    this.SESSION_TIME = 20 * 60 * 1000; //minutes
    this._this = this;
    this.$preloader = this.getPreloader();
    this.$popUp = $("#popUp");
    this.$back = $("#back"),
        this.$pageContent = $("#pageContent");
    this.$contentHeader = $("#contentHeader");
    this.appPath = "/app/";
    this.modelsPath = appPath + "models/";
    this.sessionTimer = undefined;
    this.php_router_adapter = appPath + "jsrouter.php";
    this.STATUS = {
        SUCCESS: 1,
        ERROR: 0,
    };
    this.AJAX_ANSWER_STATUS = {
        SUCCESS: 1,
        ERROR: 2,
    };
    this.msg = {
        needSave: "Вы действительно хотите закрыть форму? Все несохраненные данные будут утеряны!",
        closeSuccess: "Данные успешно сохранены! Закрыть форму?",
        closeFalse: "Ошибка схранения данных! Попробуйте позже, а если ошибка повторится, обратитесь к администратору. Закрыть форму?",
        dataFalse: "Ошибка получения данных! Попробуйте позже, а если ошибка повторится, обратитесь к администратору.",
        debugMode: "<h3>Тестовый режим! Отключить в admin.ecit.ru/www/app/js/functions_new.js</h3>",
    };
    this.elapsedTime = 0;
    this.event = null;

    //события
    this.onLoadInModal = function () {
    };
    this.onLoadInModalError = function () {
    };

    this.init();

    /**
     * универсальная функция обрабатывающая ссылки приложения
     * а также получение данных из форм
     * @param controller - файл обработчик
     * @param action - команда для обработки
     * @param params - массив аргументов
     */
    this.route = function (controller, action, params) {

        controller = (!controller) ? "main" : controller;

        let $container = $pageContent,
            showData = function (data, status) {
                _this.removePreloader();
                if (status === STATUS.SUCCESS) {
                    if ($popUp.is(":visible")) {
                        _this.hidePopup();
                    }
                    $container.html(data);
                } else {
                    let messageDataFalse = "Ошибка роутера!";
                    console.log(data);
                    $container.html(messageDataFalse);
                }
                $contentHeader.hide();
            };

        //если это не вызов по кнопке Назад, запишем состояние
        if (this.event === null) {
            var stateName = (action !== undefined && action !== null) ? controller + "." + action : controller,
                args = [].slice.call(arguments); //Convert to array
            args[4] = IS_NEW;
            _this.lm.setLocation(
                JSON.stringify(args),
                LOCAL_STATE_NAME,
                _this.lm.getUrlString(stateName.toLowerCase()),
            );
            //снова установим
            this.event = null;
        }

        _this.setPreloader($container);
        _this.ajaxRouterHelper(controller, action, params, showData);

    };

    this.ajaxRouterHelper = function (controller, action, params, callback) {
        $.ajax({
            //обращаемся к контроллеру-прокладке, получаем оттуда содержимое страниц
            url: php_router_adapter,
            dataType: "html",
            type: "POST",
            data: {
                controller: controller,
                action: action,
                params: params,
            },
            success: function (data) {
                callback(data, STATUS.SUCCESS);
            },
            error: function (data) {
                callback(data, STATUS.ERROR);
            },
        });
    };

    this.getStateName = function () {
        return LS_STATE_NAME;
    };

    this.getNewStateName = function () {
        return LOCAL_STATE_NAME;
    };


    /**
     * новый вариант обаботки запросов на сохранение или удаление информации
     * @param inModal - флаг вывода результатов в модальном окне
     * @param form (String) - ID формы для обработки (на случай если на странице много форм)
     * @param type - тип операции - удаление, добавление, редактирование
     * @param class_name - класс для обработки типа данных
     * @param container - (JQUERY объект) - контейнер для помещения в него ответов на запрос
     */
    this.actionRequestSend = function (inModal, form, type, class_name, container) {
        var $form, sendData, $container;

        if (form !== undefined) {
            $form = $("#" + form);
        } else {
            $form = $("form");
        }

        sendData = $form.serialize();

        if (container !== undefined) {
            $container = container;
        } else {
            if (inModal) {
                $container = $popUpContent;
            } else {
                $container = $pageContent;
            }
        }

        _this.setPreloader($container);

        $.ajax({
            url: "/app/controllers/action.php",
            dataType: "html",
            type: "POST",
            data: {
                type: type,
                className: class_name,
                data: sendData,
            },
            success: function (data) {
                _this.removePreloader();
                if (DEBUG) {
                    $container.prepend(data);
                    $container.prepend(msg.debugMode);
                } else {
                    if (inModal) {
                        _this.closePopup(msg.closeSuccess);
                        _this.refreshPage();
                    }
                }
                $container.html(data);
                $contentHeader.hide();
            },
            error: function (code) {
                _this.removePreloader();
                if (DEBUG) {
                    $container.prepend(code);
                    $container.prepend(msg.debugMode);
                } else {
                    if (inModal) {
                        _this.closePopup(msg.closeSuccess);
                        _this.refreshPage();
                    }
                }
                $container.html(code);
                $contentHeader.hide();
            },
        });

    };

    /**
     * обрабатывает формы, пренаправляет их контроллеру и выводит на странице или в модальном окне
     * @param inModal - флаг вывода результатов в модальном окне
     * @param form (String) - ID формы для обработки (на случай если на странице много форм)
     * @param container (JQUERY объект) - контейнер для помещения в него результатов
     */
    this.setData = function (inModal, form, container) {

        let $form, sendData, name, $container, _this = this;

        if (form !== undefined) {
            $form = $("#" + form);
        } else {
            $form = $("form");
        }

        sendData = $form.serialize();
        name = $form.attr("action");

        if (container !== undefined) {
            $container = container;
        } else {
            if (inModal) {
                $container = $popUpContent;
            } else {
                $container = $pageContent;
            }
        }
        _this.setPreloader($container);

        $.ajax({
            url: modelsPath + name + ".php",
            dataType: "html",
            type: "POST",
            data: {
                name: name,
                data: sendData,
            },
            success: function (data) {

                _this.removePreloader();
                if (DEBUG) {
                    $container.prepend(data);
                    $container.prepend(msg.debugMode);
                } else {
                    if (inModal) {
                        console.log(data);
                        _this.closePopup(msg.closeSuccess);
                        _this.refreshPage();
                    }
                }
                $container.html(data);
                $contentHeader.hide();
            },
            error: function (code) {
                _this.removePreloader();
                if (DEBUG) {
                    $container.prepend(code);
                    $container.prepend(msg.debugMode);
                } else {
                    if (inModal) {
                        _this.closePopup(msg.closeFalse);
                        _this.refreshPage();
                    }
                }
                $container.html(code);
                $contentHeader.hide();
            },
        });
    };

    this.deleteData = function (name, id) {
        if (!_this.getConfirm("Действительно удалить?")) {
            return false;
        }
        $.ajax({
            url: modelsPath + name + ".php",
            dataType: "html",
            type: "POST",
            data: {
                name: name,
                data: id,
            },
            success: function (data) {
                if (!DEBUG) {
                    _this.refreshPage();
                } else {
                    $pageContent.prepend(data);
                    $pageContent.prepend(msg.debugMode);
                }
            },
            error: function (data) {
                if (!DEBUG) {
                    _this.showError("Не удалось удалить элемент!");
                } else {
                    $pageContent.prepend(data);
                    $pageContent.prepend(msg.debugMode);
                }
            },
        });
    };

    /**
     * реализует новую процедуру удаления объектов
     */
    this.deleteDataUni = function (className, id) {
        if (!_this.getConfirm("Действительно удалить?")) {
            return false;
        }
        $.ajax({
            url: "/app/controllers/action.php",
            dataType: "html",
            type: "POST",
            data: {
                type: "delete",
                className: className,
                ID: id,
            },
            success: function (data) {
                if (!DEBUG) {
                    _this.refreshPage();
                } else {
                    $pageContent.prepend(data);
                    $pageContent.prepend(msg.debugMode);
                }
            },
            error: function (data) {
                if (!DEBUG) {
                    _this.showError("Не удалось удалить элемент!");
                } else {
                    $pageContent.prepend(data);
                    $pageContent.prepend(msg.debugMode);
                }
            },
        });
    };

    this.getPopup = async (data) => {
        await $back.fadeIn(500);
        $body.addClass(options.modalBodyClass);
        $popUpHeader.hide();
        $popUpContent.html(data);
        let _$closeBtn = $popUpContent.find(".close_button, .btn-cancel, .cancel_button, .button_cancel");
        await $popUp.fadeIn(500);
        $(_$closeBtn).on("click touchstart", _this.closePopup);
        $($back).on("click touchstart", _this.closePopup);
        _this.onLoadInModal();
    };

    this.closePopup = function (event) {
        if (event){
            event.preventDefault();
        }
        //если это был клик с кнопки
        if (!_this.getConfirm(msg.needSave, event)) {
            return false;
        }
        _this.hidePopup();
    };

    this.hidePopup = function () {
        $body.removeClass(options.modalBodyClass);
        $back.hide(0);
        $popUpHeader.html("");
        $popUpContent.html("");
        $popUp.hide(0, function () {
            $closeButton.off("click touchstart");
            $back.off("click touchstart");
        });
        $(document).off("keypress.closePopup");
    };

    this.showError = function (msg) {
        window.alert(msg);
    };

    this.getAlert = function (msg) {
        return window.alert(msg);
    };

    this.getConfirm = async function (msg, event) {
        return await app.getConfirm(msg, event);
    };

    this.refreshPage = function () {
        try {
            _this.loadHistory(history.state);
        } catch (e) {
            console.log("Не могу загрузить состояние из-за запрета записи в LocalStorage");
            location.reload();
        }
    };

    this.clearLocalStorage = function () {
        window.event.preventDefault();
        if (window.confirm("Будут удалены все сохраненные состояния (сортировки, фильтры и пр.). ОК?")) {
            localStorage.clear();
        }
    };

    this.setContentHeader = function (text) {
        $contentHeader.html(text);
    };

    this.setPreloader = function ($target) {
        $target.append(this.$preloader);
    };

    this.removePreloader = function () {
        this.$preloader.remove();
    };

    /**
     * метод обеспечивает сохранение и извлечение history
     */
    this.lm = {
        setLocation: function (data, name, curLoc) {
            try {
                history.pushState(data, name, curLoc);
                //сохраним статус для возможности открыть
                saveDataStatus(history.state);
                return;
            } catch (e) {
            }
            location.hash = "#" + curLoc;
        },
        getHistoryNameString: function (name) {
            if (name.length > 0) {
                let ar = name.split(".");
                return ar.join(" ");
            }
        },
        getUrlString: function (name) {
            if (name.length > 0) {
                let ar = name.split(".");
                return "/" + ar.join("/");
            }
        },
        getHistoryStateList: function () {
            if (history.length > 0) {
                let ar = [];
                for (let i; i <= history.length; i++) {
                    ar.push(history.state[i]);
                }
                return "history is: " + history;
            }
        },
    };

    this.showLocalStorage = function () {
        console.log(localStorage);
    };

    function saveDataStatus(state) {
        localStorage.objectOperate(LS_STATE_NAME, state);
    }

}

/**
 * инициирует необходимые процедуры
 */
Diary.prototype.init = function () {

    let _this = this;

    //закрытие сообщений сайта по кнопке з
    $(".close").click(function () {
        $(this).parent(".close").alert("close");
    });

};

Diary.prototype.doAjax = function (model_name, data, callback, dataType) {
    if (dataType === undefined) {
        dataType = "html";
    }
    $.ajax({
        url: this.modelsPath + model_name + ".php",
        dataType: dataType,
        type: "POST",
        data: data,
        success: function (answer) {
            callback(answer);
        },
        error: function (answer) {
            callback(answer);
        },
    });
};


Diary.prototype.sendAjaxRequest = function (url, data, callback) {
    if (data === "") {
        data = {};
    }
    data.new_xhr_request_form = true;
    $.ajax({
        url: url,
        dataType: "html",
        type: "POST",
        data: data,
        success: function (answer) {
            callback(answer);
        },
        error: function (answer) {
            callback(answer);
        },
    });
};

/**
 * принимает запросы на получение данных, перенаправляет их контроллеру и получает обратно сведения
 * @param name - имя контроллера
 * @param sort - передача условий для сортировки
 * @param filter - передача условий для фильтрации
 * @param select - ID элемента для вывода детальной информации
 * @deprecated - необходимо заменить на стандартный роутинг
 */
Diary.prototype.getData = function (name, sort, filter, select) {

    let curSort = localStorage.objectOperate(name + ".sort", sort),
        curFilter = localStorage.objectOperate(name + ".filter", filter),
        _this = this;

    this.setPreloader(this.$pageContent);

    if (this.event === null) {
        this.lm.setLocation(
            JSON.stringify(arguments),
            this.lm.getHistoryNameString(name),
            this.lm.getUrlString(name),
        );
        this.event = null;
    }

    $.ajax({
        url: _this.modelsPath + name + ".php",
        dataType: "html",
        type: "POST",
        data: {
            name: name,
            sort: curSort,
            filter: curFilter,
            select: select,
        },
        success: function (data) {
            _this.$pageContent.html(data);
            _this.$contentHeader.hide();
            _this.removePreloader();
        },
        error: function (code) {
            _this.setContentHeader("Обнаружены ошибки");
            _this.$pageContent.html(code);
            _this.removePreloader();
        },
    });

};

/**
 * принимает данные, пренаправляет их контроллеру и выводит в модальном окне
 * @param name
 * @param select
 */
Diary.prototype.getDataInModal = function (name, select) {
    let _this = this;
    this.setPreloader(this.$popUp);
    $.ajax({
        url: this.modelsPath + name + ".php",
        dataType: "html",
        type: "POST",
        data: {
            name: name,
            select: select,
        },
        success: function (data) {
            _this.getPopup(data);
            _this.removePreloader();
        },
        error: function (code) {
            _this.getPopup("Обнаружены ошибки");
            _this.removePreloader();
            _this.onLoadInModalError();
        },
    });
};

Diary.prototype.uploadFiles = function (formData, callback) {
    let _this = this;
    this.setPreloader(this.$popUp);
    $.ajax({
        url: "/app/models/upload.php",
        dataType: "text",
        cache: false,
        contentType: false,
        processData: false,
        data: formData,
        type: "post",
        success: function (response) {
            _this.removePreloader();
            callback("success", response);
        },
        error: function (response) {
            _this.removePreloader();
            callback("error", "Ошибка загрузки файлв!");
        },
    });
};


/**
 * получает выборку элементов из базы и возвращает их в выпадающий селект по месту запроса
 * @param className - класс который искать
 * @param field - поле для поиска
 * @param searchValue - искомая строка
 * @param fullString - false || true - признак поиска подстроки или строки целиком
 * @param callback - функция для обработки ответа
 */
Diary.prototype.getElementsByFieldValue = function (className, field, searchValue, fullString, callback) {
    $.ajax({
        url: this.modelsPath + "getElementsByFieldValue.php",
        dataType: "JSON",
        type: "POST",
        data: {
            className: className,
            field: field,
            searchVal: searchValue,
            fullString: fullString,
        },
        success: function (response) {
            callback(response);
        },
        error: function (response) {
            return response;
        },
    });
};

Diary.prototype.loadHistory = function (state) {
    let ar = this.utils.toArray(JSON.parse(state));
    //TODO убрать после изменения алгоритма роутнинга
    //обработаем новые ссылки по новому
    if (ar.indexOf(this.IS_NEW) >= 0) {
        this.route.apply(this, ar);
    } else {
        this.getData.apply(this, ar);
    }
};


Diary.prototype.utils = {};

Diary.prototype.getPreloader = function () {
    "use strict";
    return $("<div class='preloader_container'>" +
        "<div class='sk-fading-circle'>\n" +
        "<div class='sk-circle1 sk-circle'></div>\n" +
        "<div class='sk-circle2 sk-circle'></div>\n" +
        "<div class='sk-circle3 sk-circle'></div>\n" +
        "<div class='sk-circle4 sk-circle'></div>\n" +
        "<div class='sk-circle5 sk-circle'></div>\n" +
        "<div class='sk-circle6 sk-circle'></div>\n" +
        "<div class='sk-circle7 sk-circle'></div>\n" +
        "<div class='sk-circle8 sk-circle'></div>\n" +
        "<div class='sk-circle9 sk-circle'></div>\n" +
        "<div class='sk-circle10 sk-circle'></div>\n" +
        "<div class='sk-circle11 sk-circle'></div>\n" +
        "<div class='sk-circle12 sk-circle'></div>\n" +
        "</div>" +
        "</div>");
};
