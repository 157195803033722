export class AlertBlock {

    constructor() {
        this.alert = $(`<div class='alert'></div>`);
    }

    showSuccess(message) {
        this.alert.addClass("alert-success")
            .removeClass("alert-danger")
            .html(message);
    }

    showError(message) {
        this.alert.addClass("alert-danger")
            .removeClass("alert-success")
            .html(message);
    }

    remove() {
        this.alert.remove();
    }

    getNode() {
        return this.alert;
    }

}