import {Utils}      from "./Utils";
import {legacy}     from "../legacy";
import {diary}      from "../index";
import {Modal}      from "./widgets/Modal";
import {AlertBlock} from "./widgets/AlertBlock";
import {ModalSizes} from "./enums/ModalSizes";

const _ = require('lodash/string');
const axios = require('axios').default;

export class Router {

    /**
     * подгружает классы для каждой страницы
     */
    static initPageLoader(fileName = "", params = {}) {
        let {controller, action} = app.request;
        action = action === "index" ? "" : action;
        let currentClass = fileName ? fileName : _.upperFirst(controller) + _.upperFirst(_.camelCase(action));
        if (app.DEBUG) {
            console.log(`Подключаемый файл: /app/js/classes/pages/${currentClass}.js`);
        }
        Router._initPageLoading(currentClass, params);
    }

    static _initPageLoading(className, params) {
        let _class = import((`./pages/${className}.js`))
            .then(
                (_class, params) => {
                    try {
                        new _class[className](params);
                    } catch (e) {
                        if (app.debug) {
                            console.log(`Ошибка подключения класса ${className}. Содержание: ${e}`);
                        }
                    }
                })
            .catch(error => {
                if (app.debug) {
                    console.log(`Не определен класс ${className} в классе PageClassFactory`, error.message);
                }
            });
    }

    /**
     * разберем старые onclick функции, распределим их по модулям
     */
    static routeOldStyleLink(event) {
        let handlersStr = $(this).attr("onclick");
        if (!handlersStr) {
            return;
        }
        let handlersAr, methodName, args;
        handlersAr = handlersStr.split(";");
        for (let i = 0; i < handlersAr.length; i++) {
            let handler = handlersAr[i];
            if (handler.length > 0) {
                let parts = handler.match(/(.*)\((.*)\)(\[([^\]]*)\])?/);
                if (!parts) {
                    continue;
                }
                args = parts[2].split(",");
                args = args.map((element) => element.replace(new RegExp("'", 'g'), ""));
                methodName = parts[1].trim();
                if (!handler.includes(".")) {
                    legacy[methodName].apply(legacy, args);
                } else {
                    methodName = methodName.split(".")[1];
                    diary[methodName].apply(diary, args);
                }
                event.preventDefault();
            }
        }
        Router.initPageLoader();
    }

    /**
     * открывает url в модальном окне по новой схеме
     * @param event
     */
    static async routeInModal(event) {
        event.preventDefault();
        let $clicked = $(event.currentTarget),
            url = $clicked.attr("href"),
            size = $clicked.attr("data-modal-size"),
            callback = $clicked.attr("data-callback");
        await Router.openInModal(url, size, callback);
    };

    /**
     * открывает url в модальном окне по новой схеме
     * @param route
     * @param modalSize
     */
    static async openInModal(route, modalSize, callback) {
        let answer = new AlertBlock();
        try {
            const response = await axios.get(route, {
                "params": {
                    "new_xhr_request_form": true,
                },
            }).then((response) => {
                new Modal(response.data, modalSize, callback);
            }).catch((error) => {
                if (error.response.status === 404) {
                    answer.showError("Запрошенный объект не найден!");
                } else {
                    answer.showError("Ошибка обработки запроса!");
                }
                new Modal(answer.getNode(), ModalSizes.small, callback);
                if (app.config.debug) {
                    console.log(error);
                    console.log(response);
                }
            });
            // await diary.getPopup(response.data);
        } catch (errors) {
            answer.showError("Ошибка выполнения запроса!");
            new Modal(answer.getNode(), ModalSizes.small, callback);
            if (app.config.debug) {
                console.log(errors);
            }
        }
    };
}
